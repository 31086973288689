<!--
 * @Page:一日清单
 * @Author: 张阳帅
 * @Date: 2021-03-13 11:08:52
 * @LastEditTime: 2024-11-25 13:34:37
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \szlsnk-user-mp\src\views\gjc\sever\dayList.vue
-->
<template>
  <div class="day_list_page">
    <van-sticky :offset-top="50">
      <div class="page_top">
        <div class="one_item">
          <div class="item_name flex-center">
            {{ patientDict.xm }} {{ patientDict.xbmc }} {{ patientDict.age }}岁
          </div>
          <div>住院号: {{ dataDetail.zyh }}</div>
          <div class="item_right">
            <div>总费用: {{ dataDetail.zyTotalFee }} {{ dataDetail.zyTotalFee?'元':'' }}</div>
          </div>
        </div>
        <div class="top_day">
          <van-button class="cell-btn" type="primary" size="mini" @click="onLastDay">
            上一天
          </van-button>
          <div class="day_item" @click="changeDayShow = true">
            {{ queryTime }}
          </div>
          <van-button class="cell-btn" type="primary" size="mini" :disabled="nextDisabled" @click="onNextDay">
            下一天
          </van-button>
        </div>
      </div>
    </van-sticky>
    <div v-if=" dataDetail.gjcDailyCheckList && dataDetail.gjcDailyCheckList.length > 0" class="page_body">
      <div v-for="(item, index) in dataDetail.gjcDailyCheckList" :key="index" class="body_item">
        <div class="item_title">
          <div class="title_left">
            {{ item.feeTypeName }}
          </div>
          <div class="title_right">
            {{ item.totalAmount }}元
          </div>
        </div>
        <div v-for="(pro, aIndex) in item.dailyCheckList" :key="aIndex" class="item_cont">
          <div class="cont_list">
            <div class="list_left">
              {{ pro.feeName }}
            </div>
            <div class="list_num">
              x{{ pro.feeNum }}
            </div>
            <div class="list_right">
              {{ pro.totalAmount }}元
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="page_body">
      <van-empty description="暂无当日数据" />
    </div>
    <div class="page_bottom">
      <div class="bottom_price">
        合计：<a class="price_text">{{ dataDetail.totalAmount }}</a>元
      </div>
    </div>
    <van-calendar v-model="changeDayShow" :default-date="defaultDate" :min-date="minDate" :max-date="maxDate" @confirm="onConfirm" />
  </div>
</template>
<script>
import { gjcModel } from '@/api/gjcApi'
import pageRefreshLoad from '@/assets/js/mixin/pageRefreshLoad'

export default {
    name: 'AppointmentList',
    mixins: [pageRefreshLoad],
    data() {
        return {
            patientDict: {},//就诊人字典
            patientCardId: '',
            patientInfoId: '',
            changeDayShow: false,
            defaultDate: new Date(),
            minDate: new Date(2020, 0, 1),
            maxDate: new Date(),
            queryTime: this.formatDate(new Date()),
            dataDetail: {},
            nextDisabled:true,
            today: null
        }
    },
    created() {
        this.patientDict = JSON.parse(this.$store.getters.patient)
        this.patientId = this.patientDict.patientId
        this.patientCardId = this.patientDict.patientCardList[0].patientCardId
        // 获取今天的日期，并去掉时间部分
        this.today = new Date().setHours(0, 0, 0, 0)
    },
    mounted() {
        this.getList()
    },
    methods: {
        onLastDay() {
            let date = new Date(this.queryTime)
            date.setDate(date.getDate() - 1)
            this.nextDisabled = false
            this.queryTime = this.formatDate(date)
            this.getList()
        },
        onNextDay() {
            let date = new Date(this.queryTime)
            date.setDate(date.getDate() + 1)
            // 去掉时间部分，只保留年月日
            date.setHours(0, 0, 0, 0)
            // 只比较年月日
            if (date >= this.today) {
              this.nextDisabled = true
            }
            this.queryTime = this.formatDate(date)
            this.getList()
        },
        //一日清单
        async getList() {
            let params = {
                patientCardId: this.patientCardId,
                patientInfoId: this.patientId,
                hospCode: '',
                queryTime: this.queryTime
            }
            const res = await gjcModel.getDailyCheckListData(params)
            if (res.data) {
              this.dataDetail = res.data || {}
            }else{
              this.$toast('当前患者暂无住院数据')
            }
            
        },
        formatDate(date) {
          let day = this.padZero(date.getDate())
          let month = this.padZero(date.getMonth() + 1)
          let year = date.getFullYear()
          return `${year}-${month}-${day}`
        },
        padZero(num) {
          return num < 10 ? '0' + num : num
        },
        onConfirm(date) {
            this.changeDayShow = false
             // 创建一个 Date 对象
            const newDate = new Date(date)
            this.defaultDate = date
            // 获取时间戳（以毫秒为单位）
            const timestamp = newDate.getTime()
            if (timestamp >= this.today) {
              this.nextDisabled = true
            }else{
              this.nextDisabled = false
            }
            this.queryTime = this.formatDate(date)
            this.getList()
        }
    }
}
</script>
<style scoped lang='less'>
.day_list_page {
    background-color: #f1f1f1;
}

.page_top {
    border-bottom: 1px solid #f5f5f5;
    background-color: white;
    .one_item {
      display: flex;
      justify-content: space-between;
      justify-items: center;
      padding: 24px 32px;
      color: white;
      background-color: #1e80ff;
      border-bottom: 1px solid #f5f5f5;
      font-size: 24px;
    }
    .top_day {
        display: flex;
        justify-content: space-between;
        justify-items: center;
        padding: 20px 24px;
        background-color: white;

        .day_item {
            flex: 1;
            margin-left: 24px;
            margin-right: 24px;
            border: #999 1px solid;
            font-size: 32px;
            color: #333;
            line-height: 50px;
            text-align: center;
        }

        .day_btn {
            font-size: 32px;
            color: #333;
            line-height: 50px;
        }
    }
}

.page_body {
    padding-bottom: 120px;
    .body_item {
        margin: 20px 24px;
        background-color: white;
        border-radius: 12px;
        padding: 20px 24px;

        .item_title {
            display: flex;
            justify-content: space-between;
            font-size: 32px;
            color: #333;
            line-height: 60px;
            padding-bottom: 10px;

            // border-bottom: #999 1px solid;
            .title_right {
                color: #d00;
            }
        }
    }

    .item_cont {
        border-top: #ccc 2px solid;
        padding: 10px 0;

        .cont_list {
            display: flex;
            margin: 10px;
            font-size: 30px;
            color: #666;

            .list_left {
                width: 60%;
            }

            .list_num {
                width: 20%;
                text-align: center;
            }

            .list_right {
                width: 20%;
                text-align: right;
            }
        }
    }

}

.page_bottom {
    position: fixed;
    bottom: 0;
    width: 100%;
    background: #ffffff;
    padding: 16px 32px;
    box-sizing: border-box;

    .bottom_price {
        padding: 15px 0;
        font-size: 32px;
        color: #333;
        width: 100%;
        text-align: right;

        .price_text {
            color: #d00;
        }
    }
}
.flex-center{
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>